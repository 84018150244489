import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.section`
  h2, p {
    font-family: "Sora", Helvetica, sans-serif;
  }

  padding: 40px 0;

  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }
`

export const WrapperGrid = styled.section`
  @media ${device.desktopLG} {
    gap: 24px;
    display: grid;
    grid-template-columns:[start] 41% [secondCol] 8% [thirdCol] 41% [end];
    grid-template-rows: [firstRow] 200px [secondRow] 330px [thirdRow] 250px [fourthRow];
  }

  @media ${device.desktopXL} {
    gap: 24px;
    display: grid;
    grid-template-columns:[start] 45% [secondCol] 10% [thirdCol] 45% [end];
    grid-template-rows: [firstRow] 300px [secondRow] 480px [thirdRow] 350px [fourthRow];
  }
`

export const DigitalAccount = styled.div`
  background-color: ${orange.base};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/super-app/image.webp');
  border-radius: 36px;
  padding: 24px;
  min-height: 340px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 190px;
  text-align: center;

  @media ${device.tablet} {
    background-size: 300px;
    min-height: 480px;
    padding: 40px;
  }

  @media ${device.desktopLG} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    background-size: 350px;
    text-align: left;
    padding: 30px;
    background-position: center 128px;
  }

  @media ${device.desktopXL} {
    padding: 45px;
    background-size: 420px;
    background-position: center 290px;
  }
`

export const DebitCard = styled.div`
  background-color: ${grayscale[100]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-global/image.webp');
  border-radius: 36px;
  padding: 24px;
  margin: 24px 0;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: 130px;
  background-position: 24px 54px;
  text-align: right;

  @media ${device.tablet} {
    background-position: 24px 20px;
    background-size: 270px;
    min-height: 300px;
    padding: 40px;
  }

  @media ${device.desktopLG} {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    background-position: 300px;
    background-size: 150px;
    padding: 24px 40px;
    margin: 0;
    min-height: 200px;
    text-align: left;
  }

  @media ${device.desktopXL} {
    padding: 45px;
    background-position: 385px 68px;
    background-size: 200px;
  }
`

export const Transfer = styled.div`
  background-color: ${grayscale[100]};
  border-radius: 36px;
  padding: 24px;
  margin: 24px 0;
  min-height: 300px;
  position: relative;

  @media ${device.tablet} {
    padding: 40px;
  }

  @media ${device.desktopLG} {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    background-image: none;
    padding: 40px;
    margin: 0;
  }

  @media ${device.desktopXL} {
    padding: 45px;
  }

  .image {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-com-cartao-global/image.webp');
    background-position: bottom right;
    background-size: 180px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 600px;
    width: 100%;

    @media ${device.tablet} {
      background-size: 215px;
    }

    @media ${device.desktopLG} {
      background-size: 225px;
    }

    @media ${device.desktopXL} {
      background-size: 330px;
    }
  }

`

export const GiftCard = styled.div`
  background-color: ${grayscale[100]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cupon-laranja/image.webp');
  border-radius: 36px;
  padding: 24px;
  margin: 24px 0;
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: 170px;
  background-position: 24px 114px;
  text-align: right;

  @media ${device.tablet} {
    background-position: 40px 44px;
    background-size: 200px;
    min-height: 300px;
    padding: 40px;
  }

  @media ${device.desktopLG} {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    background-position: 30px 44px;
    background-size: 150px;
    min-height: 250px;
    padding: 40px;
    margin: 0;
  }

  @media ${device.desktopXL} {
    padding: 45px;
    background-position: 30px 88px;
    background-size: 210px;
  }
`

export const Investment = styled.div`
  background-color: ${grayscale[100]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dolar/image.webp');
  border-radius: 36px;
  padding: 24px;
  margin: 24px 0;
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: bottom right;
  text-align: left;

  @media ${device.tablet} {
    background-position: bottom right;
    background-size: 400px;
    padding: 40px;
  }

  @media ${device.desktopLG} {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
    background-position: bottom right;
    background-size: 200px;
    padding: 24px 40px;
    margin: 0;
    min-height: 250px;
  }

  @media ${device.desktopXL} {
    padding: 45px;
    background-size: 300px;
  }
`
